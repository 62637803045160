<template>
  <b-container class="bg-white" fluid>
    <b-row class="mb-4">
      <b-col>
        <b-link :to="{ name: 'AppImporting' }">Back to data loading</b-link>
        <b-button variant="outline-primary" class="mb-2" @click="importItems">import</b-button>
      </b-col>
    </b-row>
    <b-row v-if="importing">
      <b-col>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        imported: {{counter}} records
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container>
          <h1>Import</h1>
          <div v-if="news.length > 0">
            <h2>News to import</h2>
            <div class="mb-3" v-for="(item, index) in news" :key="'news' + index">
              <div>{{index + 1}}</div>
              <div>{{item.date}}</div>
              <div>{{item.name}}</div>
              <div>{{item.body}}</div>
              <div>{{item.link}}</div>
              <div>{{item.publisher}}</div>
              <div>{{item.source}}</div>
              <div>{{item.type}}</div>
              <div>{{item.countryCode}} - {{item.countryName}} - {{item.langCode}} - {{item.langName}}</div>
            </div>
          </div>
          <div v-if="newsImported.length > 0">
            <h2>News imported</h2>
            <div class="mb-3" v-for="(item, index) in newsImported" :key="'news' + index">
              <div>{{index + 1}}</div>
              <div>{{item.date}}</div>
              <div>{{item.name}}</div>
              <div>{{item.body}}</div>
              <div>{{item.link}}</div>
              <div>{{item.publisher}}</div>
              <div>{{item.source}}</div>
              <div>{{item.type}}</div>
              <div>{{item.countryCode}} - {{item.countryName}} - {{item.langCode}} - {{item.langName}}</div>
            </div>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import _ from 'lodash'
import async from 'async'
import config from './config.js'

export default {
  name: "ImportingAppMain",
  components: {
  },
  data () {
    return {
      counter: 0,
      importing: false,
      news: [],
      newsImported: []
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'Importing app main', action: 'open Importing app main page' })
    const raw = {}
    config.files.forEach(x => {
      raw[x.id] = JSON.parse(localStorage.getItem('frmcloudImportingApp' + x.id))
    })
    if (raw.news) {
      this.news = raw.news
    }
    console.log(raw)
  },
  methods: {
    importItems: async function () {
      this.counter = 0
      this.importing = true
      try {
        if (this.news.length > 0) {
          await async.eachSeries(this.news, async.asyncify(this.importNews))
        }
      } catch (e) {
        console.log('importing error')
        console.log(e)
      }
      this.importing = false
    },
    importNews: async function (item) {
      try {
        const params = {
          body: item
        }
        await this.$Amplify.API.put('cosmos', '/news/publication', params)
        this.newsImported.push(item)
      } catch (e) {
        console.log('error saving item')
        console.log(e)
      }
    }
  }
}
</script>

<style>
</style>
